<template>
  <div class="userIndexpage" ref="userPage">
    <div class="userIndexpageCon">
      <!-- 个人信息模块 -->
      <div class="userInfo">
        <div class="TopTitle">
          <span>{{ $t("User.txt23") }}</span>
        </div>
        <!-- 头像 -->
        <div class="item avater">
          <span class="userTitle">{{ $t("User.txt1") }}:</span>
          <div class="setUserimg" @click="changeAvater">
            <img :src="avatar" alt="" />
            <div class="hover">
              <img src="@/newImg/avaterHover.png" alt="" />
            </div>
            <input
              type="file"
              accept="image/jpeg,image/jpg,image/png,image/svg,image/bmp,image/jpe,image/jfif,image/tif,image/tiff"
              multiple="multiple"
              @change="handleFileChange"
              class="fileBtn"
              ref="fileInput"
              @click="clickInput"
            />
          </div>
        </div>
        <!-- 昵称 -->
        <div class="item nickName">
          <span class="userTitle">{{ $t("User.txt2") }}:</span>
          <div v-if="nickNameType" class="nickNameInputBox">
            <input
              type="text"
              class="nickNameInput"
              :placeholder="nameInputFoucsType ? '' : '请输入昵称'"
              v-model="nickNameVal"
              maxlength="10"
              @focus="nameInputFoucsType = true"
              @blur="nameInputFoucsType = false"
            />
            <div class="saveBtn saveNameBtn" @click="saveNickName">
              <span>{{ $t("User.txt21") }}</span>
            </div>
            <div class="saveBtn cancleEditBtn" @click="nickNameType = false">
              <span>{{ $t("User.txt18") }}</span>
            </div>
          </div>
          <div class="userContxt" v-else>
            <span>{{ nickNameVal }}</span>
          </div>
          <div
            class="editName"
            v-show="!nickNameType"
            @click="nickNameType = true"
          >
            <span>{{ $t("User.txt20") }}</span>
          </div>
        </div>

        <!-- <div class="item accountType">
          <div class="userTitle">
            <span>{{ $t("User.txt13") }}:</span>
          </div>
          <div class="userContxt">
            <span>{{ role == 2 ? $t("User.txt24") : $t("User.txt25") }}</span>
          </div>
        </div>
        <div class="item weavingNum">
          <div class="userTitle">
            <span>{{ $t("User.txt22") }}:</span>
          </div>
          <div class="userContxtTime">
            <span>{{ exp_time }}</span>
          </div>
        </div> -->
        <div class="outLogin" @click="loginOutClick">
          {{ $t("User.txt5") }}
        </div>
      </div>
      <div :class="$store.state.index.languageType == 'zh'?'nao': 'nao naotextile'">
        <div class="title">
          <span>{{ $t("User.txt14") }}:</span>
        </div>
        <span class="website">https://basic.naotextile.com</span>
        <div class="lookBtn" @click="CopyWebSite(1)">
          <span>{{ $t("User.txt16") }}</span>
        </div>
      </div>
      <div class="nao">
        <div class="title">
          <span>{{ $t("User.txt29") }}:</span>
        </div>
        <span class="website">https://jacquard.naotextile.com</span>
        <div class="lookBtn" @click="CopyWebSite(2)">
          <span>{{ $t("User.txt16") }}</span>
        </div>
      </div>
      <div class="nao">
        <div class="title">
          <span>{{ $t("User.txt15") }}:</span>
        </div>
        <span class="website">https://www.naotextile.com</span>
        <div class="lookBtn" @click="CopyWebSite(3)">
          <span>{{ $t("User.txt16") }}</span>
        </div>
      </div>
      <div class="nao">
        <div class="title">
          <span>{{ $t("User.txt3") }}</span>
        </div>
        <div class="lookBtn" @click="pageType = 1">
          <span>{{ $t("User.txt6") }}</span>
        </div>
      </div>
      <div class="nao officialAccounts">
        <div class="title">
          <span>{{ $t("User.txt4") }}</span>
        </div>
        <div class="lookBtn" @click="pageType = 2">
          <span>{{ $t("User.txt6") }}</span>
        </div>
      </div>
    </div>
    <div class="popup" v-show="showAvater">
      <div class="mask" v-show="showAvater" @click="showAvater = false"></div>
      <div class="avaterPopupContent" v-show="showAvater">
        <div class="top">
          <span>{{ $t("User.txt17") }}</span>
        </div>
        <div class="cancleBtn" @click="showAvater = false">
          <img src="@/images/Close.png" alt="" />
        </div>
        <div class="avaterContent">
          <div class="setAvater">
            <img class="avaterImg" :src="avatarImg" alt="" />
          </div>
          <div class="avaterBtn">
            <div class="btn cancle" @click="showAvater = false">
              <span>{{ $t("User.txt18") }}</span>
            </div>
            <div class="btn upload" @click="uploadUserAvatar">
              <span>{{ $t("User.txt19") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pupAppShow :type="pageType" @result="cancelDialog" />
  </div>
</template>

<script>
import {
  getUserInfo,
  uploadUserImg,
  bindEmail,
  bindUserName,
  userLoginOut,
} from "@/api/user/user";
import pupAppShow from "@/components/pupApp/pupAppShow"; //意匠图组件
import axios from "axios";
import { getToken } from "@/utils/utils";
export default {
  components: { pupAppShow },
  name: "userIndex",
  data() {
    return {
      userInfoList: {
        userName: "",
        phone: "",
        email: "",
        invitationCode: "",
      },
      surplusCount: 0,
      role: "", //账号类型
      exp_time: "", //到期时间
      showNaoApp: false,
      showOfficialAccounts: false,
      showAvater: false,
      emailVal: "", //邮箱
      headImglist: [
        require("@/images/userHeadImg0.png"),
        require("@/images/userHeadImg1.png"),
        require("@/images/userHeadImg2.png"),
        require("@/images/userHeadImg3.png"),
      ],
      headPuptype: false,
      emailType: false,
      nickNameType: false,
      avatar: "", //头像
      nickNameVal: "", //昵称
      nameInputFoucsType: false,
      emaillInputFoucsType: false,
      // 是否取消选择文件
      fileCancle: true,
      avatarFile: "",
      avatarImg: "",
      pageType: 0,
    };
  },
  mounted() {
    this.GetUser();
  },
  watch: {
    nickNameVal(newVal) {
      // this.nickNameVal = newVal.replace(/[^\a-\z\A-\Z\u4E00-\u9FA5]/g, "");
    },
  },
  methods: {
    changeAvater() {
      this.$refs.fileInput.dispatchEvent(new MouseEvent("click"));
    },
    clickInput() {
      window.addEventListener("focus", this.handleFocusCallBack);
    },
    handleFocusCallBack() {
      setTimeout(() => {
        if (this.fileCancle) {
          window.removeEventListener("focus", this.handleFocusCallBack);
        }
      }, 500);
    },
    handleFileChange(e) {
      //获取导入图片
      this.fileCancle = false;
      // this.$refs.fileInput.files[0]
      if (this.$refs.fileInput.files[0] != undefined) {
        this.showAvater = true;
        this.avatarFile = this.$refs.fileInput.files[0];
        this.fileToBase64(this.avatarFile);
        this.$refs.fileInput.value = "";
      } else {
        this.showAvater = false;
      }
      window.removeEventListener("focus", this.handleFocusCallBack);
    },
    fileToBase64(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // console.log("file 转 base64结果：" + reader.result);
        this.avatarImg = reader.result;
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },
    uploadUserAvatar() {
      // 以下是向后端识别图片接口传递file文件

      let languageSet = 1;
      if (localStorage.getItem("languageSet") != null) {
        languageSet = localStorage.getItem("languageSet") == "zh" ? 1 : 2;
      }
      var formData = new FormData();
      formData.append("languageSet", languageSet);
      formData.append("file", this.avatarFile); // 注意是传file.raw

      axios({
        url: process.env.VUE_APP_BASE_URL + "/user/uploadPictureToSever",
        headers: {
          "Content-Type": "multipart/form-data",
          token: getToken(),
        },
        method: "post",
        data: formData,
      })
        .then((res2) => {
          if (res2.data.code == 0) {
            this.avatar = res2.data.data.url;
            this.showAvater = false;
            let userdata = {
              avatar: this.avatar,
              name: this.nickNameVal,
            };
            this.$store.commit("index/setuserInfo", userdata);
            this.$messagePup(this.$t("User.txt12"), "success");
          } else {
            this.showAvater = false;
            this.$messagePup(res.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancelDialog() {
      this.pageType = 0;
    },
    //获取个人信息
    async GetUser() {
      const { code, msg, data } = await getUserInfo();
      if (code === 0) {
        this.nickNameVal = data.nickName;
        this.avatar = data.avatar;
        this.surplusCount = data.surplusCount;
        this.exp_time = data.exp_time;
        this.role = data.jacquard_role;
      }
    },
    // 绑定昵称
    saveNickName() {
      if (this.nickNameVal != "") {
        bindUserName({ username: this.nickNameVal }).then((res) => {
          if (res.code == 0) {
            this.nickNameType = false;
            this.$messagePup(this.$t("User.txt26"), "success");
            let userdata = {
              avatar: this.avatar,
              name: this.nickNameVal,
            };
            this.$store.commit("index/setuserInfo", userdata);
          } else {
            this.$messagePup(res.msg);
          }
        });
      }
    },
    //退出登录
    async loginOutClick() {
      let res = await userLoginOut();
      if (res.code === 0) {
        this.$router.push("/loginUp");
        this.$store.commit("index/outLogin", 1);
        localStorage.removeItem("token");
        localStorage.removeItem("machineType");
        localStorage.removeItem("machineList");
      } else {
        this.$message({
          showClose: true,
          message: res.msg,
          type: "warning",
        });
      }
    },
    CopyWebSite(type) {
      if (type == 1) {
        // navigator.clipboard.writeText 该方法需要在安全域下才能够使用，比如：https 协议的地址、127.0.0.1、localhost
        navigator.clipboard
          .writeText("https://basic.naotextile.com")
          .then(() => {
            this.$message.success(this.$t("User.txt27"));
          })
          .catch((err) => {
            // 复制失败
            // console.error("复制失败");
          });
      } else if(type == 2) {
        navigator.clipboard
          .writeText("https://jacquard.naotextile.com")
          .then(() => {
            this.$message.success(this.$t("User.txt27"));
          })
          .catch((err) => {
            // 复制失败
            // console.error("复制失败");
          });
      }else{
        navigator.clipboard
          .writeText("https://www.naotextile.com")
          .then(() => {
            this.$message.success(this.$t("User.txt27"));
          })
          .catch((err) => {
            // 复制失败
            // console.error("复制失败");
          });
      }
    },
  },
};
</script>

<style lang="less" >
@import "./userIndex.less";
</style>>
